import { addQueryParamsToUrl } from "http-with-fetch";

const Endpoints = (EMBASSY: string) => ({
  LOGIN: `${EMBASSY}login`,
  VISA: `${EMBASSY}visa`,
  REFRESH: `${EMBASSY}refresh`,
  LOGOUT: `${EMBASSY}logout`
});

const init: RequestInit = { credentials: "include" };

export class SessionSdk {
  #endpoints: Record<string, string>;
  constructor(namespace: string) {
    this.#endpoints = Endpoints(`/embassy/${namespace}/`);
  }
  async create(token: string) {
    const response = await fetch(
      addQueryParamsToUrl(this.#endpoints.LOGIN, {
        auth_code: token,
        issue_redirect: false
      })
    );
    if (!response.ok) {
      throw new Error("Session is not established");
    }
  }
  async visa(): Promise<{ active: boolean } & Session> {
    return fetch(this.#endpoints.VISA, init).then(
      res => res.json(),
      () => ({ active: false })
    );
  }
  async refresh() {
    return fetch(this.#endpoints.REFRESH, init);
  }
  async logout() {
    return fetch(this.#endpoints.LOGOUT, { ...init, method: "DELETE" });
  }
}

export function createEmbassySession(namespace: string) {
  return async function EmbassySession(isRefreshNeeded: boolean) {
    const session = new SessionSdk(namespace);
    let response = await session.visa();
    if (!response.active && isRefreshNeeded) {
      let refresh = await session.refresh();
      if (refresh.ok) {
        response = await session.visa();
      }
    }
    return response.active ? response : null;
  };
}
