import pandora from "@faizaanceg/pandora";
import { Injection, Syringe } from "@faizaanceg/syringe";
import { RUM } from "@webpro/monitoring";
import { BrandConfigurationOperations } from "common/brand-configuration";
import { createEmbassySession } from "common/embassy/session";
import { retrieveFeatureFlags } from "common/flags";
import { parseSearch } from "common/qs";
import { findRuntimeSettings } from "common/runtime";
import { SiteDetailsManager } from "common/site-details";

const flags = await retrieveFeatureFlags();
const runtime = await findRuntimeSettings();

const ApiHost: Injection<"apiUrls"> = {
  name: "apiUrls",
  injectFn: ({ runtime }) => ({
    apiHost: `${runtime.gateway}obox/`,
    cacheableApiHost: `${runtime.gateway}obox-api/`,
    searchURL: `${runtime.gateway}plastic/`
  }),
  uses: [{ name: "runtime" }]
};

const CreatePortalContainer: Injection<"portalContainer"> = {
  name: "portalContainer",
  injectFn: () => document.body,
  uses: []
};

const FeatureFlags: Injection<"flags"> = {
  name: "flags",
  injectFn: () => flags,
  uses: []
};

const RuntimeSettings: Injection<"runtime"> = {
  name: "runtime",
  injectFn: () => runtime,
  uses: []
};

const ExternalAccessManagerFactory: Injection<"externalAccessManager"> = {
  name: "externalAccessManager",
  injectFn: ({ runtime }) => ({
    createPaymentUrl(key) {
      const hostname = `${
        typeof window !== "undefined" ? window.location.origin : ""
      }${runtime.backend}`;
      return `${hostname}webhooks/payments/complete/${key}`;
    },
    createWindow(target) {
      return window.open("/loader.html", target);
    },
    createCurrentHost() {
      return "";
    }
  }),
  uses: [{ name: "runtime" }]
};

const UserConstraintsFactory: Injection<"userConstraints"> = {
  name: "userConstraints",
  injectFn: ({ flags, runtime }) => ({
    addFundsOnDemand: true,
    useLegacyPayment: false,
    enableLogin: true,
    enableSmartCart: true,
    manageAutoRenewal: true,
    useConsolidatedPricing:
      flags.FEATURE_ENDPOINT === "bigrock" &&
      ["production", "canary"].includes(runtime.environment),
    hasRecentSearches: flags.FEATURE_ENDPOINT === "reseller",
    manageSitesNative: true
  }),
  uses: [{ name: "runtime" }, { name: "flags" }]
};

function getDefaultUrls(env: string, usermode: string) {
  switch (env) {
    /**
     * We prioritize production urls for local dev
     */
    case "development":
      switch (usermode) {
        case "customer":
          return "same665954.manage-orders.com";
        case "bigrock":
          return "myorders.bigrock.in";
        case "reseller":
        default:
          return "demo.webpropanel.com";
      }
    /**
     * We prioritize staging urls for PR instances
     */
    case "staging":
      if (usermode === "reseller") {
        return "staging-colo-provo.myorderbox.com";
      }
      return "20794-stage-colo-orderbox.apps.provo-staging.myorderbox.com";
    case "canary":
    default:
      return window.location.hostname;
  }
}

const SiteUrlProviderFactory: Injection<"siteUrlProvider"> = {
  name: "siteUrlProvider",
  injectFn: ({ flags, runtime }) => {
    if (runtime.environment === "production") {
      return () => window.location.hostname;
    }
    return () => {
      const qs = parseSearch(window.location.search);
      return (
        qs.url ??
        pandora.get(
          "parentbrandingurl",
          getDefaultUrls(runtime.environment, flags.FEATURE_ENDPOINT)
        )
      );
    };
  },
  uses: [{ name: "runtime" }, { name: "flags" }]
};

Syringe.fill([
  ApiHost,
  CreatePortalContainer,
  FeatureFlags,
  RuntimeSettings,
  ExternalAccessManagerFactory,
  UserConstraintsFactory,
  SiteUrlProviderFactory,
  SiteDetailsManager.register()
]);

const { AppCredentialsManager, DefaultSessionManager } = await import(
  /* webpackChunkName: "credentials" */ "./credentials"
);

Syringe.fill([
  DefaultSessionManager.register(createEmbassySession("superpanel")),
  AppCredentialsManager.register(true)
]);

const siteManager = Syringe.inject("siteManager");
const details = await siteManager.details();
if (details) {
  const brandConfiguration = BrandConfigurationOperations.findById(
    details?.resellerid,
    "customer"
  );
  if (brandConfiguration?.preferredUserRole === "reseller") {
    document.title = "WebPro Panel";
  }
  if (runtime.environment === "production") {
    const analytics = BrandConfigurationOperations.findAnalyticsId(
      brandConfiguration?.brand
    );
    RUM.init({ analytics, rum: null });
  }
}

if (flags.FEATURE_ENDPOINT === "bigrock") {
  await import(/* webpackChunkName: "brand-retail" */ "../brand/bigrock");
} else {
  await import(/* webpackChunkName: "brand-wholesale" */ "../brand/rclub");
}

await import(/* webpackChunkName: "app" */ "../index");
