class RUMService {
    getEventLayer() {
        return window.dataLayer ?? [];
    }
    setUser(user) {
        throw new Error("Method not implemented.");
    }
    addError(error, metadata) {
        if (typeof window !== "undefined") {
            window.DD_RUM?.addError(error, metadata);
        }
    }
    addEvent(event, metadata) {
        if (typeof window !== "undefined") {
            window.dataLayer?.push({ event, ...metadata });
        }
    }
    init(configuration) {
        if (configuration.rum && window.DD_RUM) {
            window.DD_RUM.init({
                env: configuration.environment,
                service: configuration.service,
                version: configuration.version,
                site: "datadoghq.com",
                ...configuration.rum
            });
        }
        window.dataLayer = window.dataLayer ?? [];
        if (configuration.analytics) {
            this.addEvent("gtm.js", { "gtm.start": new Date().getTime() });
            const script = document.createElement("script");
            script.async = true;
            script.src = `https://www.googletagmanager.com/gtm.js?id=${configuration.analytics}`;
            document.head.appendChild(script);
        }
    }
}
export const RUM = new RUMService();
