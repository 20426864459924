import { Injection } from "@faizaanceg/syringe";
import { SimpleApi } from "common/api-client";
import { BrandConfigurationOperations } from "common/brand-configuration";
import { ClientDetails, SiteDetails, SiteUrlProvider } from "../../webpro";

export class SiteDetailsManager {
  private client: ClientDetails = null;
  private backend: string = null;
  private site: Partial<SiteDetails> = null;
  private siteUrlProvider: SiteUrlProvider = null;

  constructor(backend: string, siteUrlProvider: SiteUrlProvider) {
    this.backend = backend;
    this.siteUrlProvider = siteUrlProvider;
  }
  static register() {
    const SiteManagerFactory: Injection<"siteManager"> = {
      name: "siteManager",
      injectFn: ({ runtime, siteUrlProvider }) =>
        new SiteDetailsManager(runtime.backend, siteUrlProvider),
      uses: [{ name: "runtime" }, { name: "siteUrlProvider" }]
    };
    return SiteManagerFactory;
  }

  async clientDetails() {
    if (this.client === null) {
      // TODO: Need a better fallback
      this.client = await SimpleApi.get<ClientDetails>(
        `${this.backend}user/details`
      ).catch(() => ({ ip: "", country: "" } as ClientDetails));
    }
    return this.client;
  }
  async details() {
    if (this.site === null) {
      let siteUrl = this.siteUrlProvider();
      const brandConfiguration =
        BrandConfigurationOperations.findByUrl(siteUrl);
      if (brandConfiguration?.siteDetails) {
        this.site = brandConfiguration.siteDetails;
      } else {
        this.site = await SimpleApi.get(
          `${this.backend}user/branded-url/details`,
          { "branded-url": siteUrl }
        ).catch(() => null);
      }
      if (this.site) {
        this.site.url = siteUrl;
      }
    }
    return this.site;
  }
}
