export type FeatureFlags = Record<string, any>;

export async function retrieveFeatureFlags(
  publicPath = "/"
): Promise<FeatureFlags> {
  return fetch(`${publicPath}flags.json`)
    .then(
      res => res.json(),
      () => ({})
    )
    .then(flags =>
      Object.fromEntries(
        Object.entries(flags).map(([key, value]) => [
          `FEATURE_${key.toUpperCase()}`,
          value
        ])
      )
    );
}
