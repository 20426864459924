import { CredentialsManager } from "http-with-fetch";

type SessionUrl<T> = (refreshNeeded: boolean) => Promise<T>;

export class SessionManager<SessionType = Session> {
  sessionUrl: SessionUrl<SessionType>;
  activeSession: SessionType = null;
  constructor(sessionUrl: SessionUrl<SessionType>) {
    this.sessionUrl = sessionUrl;
  }
  markInvalid() {
    this.activeSession = null;
  }
  async create(refreshNeeded = false) {
    if (this.activeSession === null) {
      this.activeSession = await this.sessionUrl(refreshNeeded).catch(
        () => null
      );
    }
    return this.activeSession;
  }
}

export class DefaultCredentialsManager implements CredentialsManager {
  manager: SessionManager<Session>;
  constructor(manager: SessionManager<Session>) {
    this.manager = manager;
  }
  async attach(init: RequestInit, url: string) {
    init.credentials = "include";
    init.headers = new Headers(init.headers ?? {});
    init.headers.set("Accept", "application/json");
    return { init, url };
  }
  async isValid(_: Request, res: Response) {
    if (res.status === 401) {
      this.manager.markInvalid();
      return false;
    }
    return true;
  }
  async refresh() {
    let session = await this.manager.create(true);
    if (session === null) {
      throw new Error("RefreshFailed");
    }
  }
}
