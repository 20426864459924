export type RuntimeSettings = {
  /**
   * This refers to the webpro-backend service. The URL provided here always ends with a /.
   * In the case of MFE, this value will be derived from gateway.
   * This service supports calls with or without session.
   */
  backend?: string;
  /**
   * This refers to the gateway-kong service. The URL provided here always ends with a /.
   */
  gateway: string;
  environment: "development" | "staging" | "canary" | "production";
};
export async function findRuntimeSettings(
  publicPath = "/"
): Promise<RuntimeSettings> {
  return fetch(`${publicPath}runtime.json`).then(
    res => res.json(),
    () => ({ gateway: "/", backend: "/backend/", environment: "production" })
  );
}
