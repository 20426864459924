import { Syringe } from "@faizaanceg/syringe";
import { DefaultCredentialsManager } from "common/credentials";
import { createApiClient } from "http-with-fetch";

const oboxToWebproMap = {
  "Internal Server Error":
    "There was some error while performing the action. Please try again in a few minutes",
  "There is already an action pending on this Order.":
    "There is already an action pending on this Order. Please try again in a few minutes",
  "{newipaddr=Invalid IP Address Format}": "Invalid IP address",
  "A Child Name Servers can only be registered under your Domain Name. For instance, a Child Name Server ns2.xyz.com maybe registered only under xyz.com":
    "Child Name Servers can only be registered under your Domain Name. For instance, a Child Name Server ns2.xyz.com maybe registered only under xyz.com",
  "Unable to send Mail : finished trying all SMTP hosts.":
    "Unable to send mail. Please try again in sometime."
};

const OboxErrorMessages = [
  "error",
  "failed",
  "There is already an action pending on this Order.",
  "failure"
];

const helpers = {
  checkForError: async (request: Request, response: Response) => {
    let error = new Error(response.statusText);
    // @ts-ignore
    error.status = response.status;
    // @ts-ignore
    error.response = response;
    const runtime = Syringe.inject("runtime");
    if (runtime.environment !== "production") {
      console.error("API Call Failed: ", error.message, request);
    }
    if (response.headers.get("Content-Type")?.includes("application/json")) {
      let apiResponse = await response.json().catch(() => ({
        message: "Some error has occurred. Please try again later"
      }));
      error.message = apiResponse.message;
      // @ts-ignore
      error.response = new Response(JSON.stringify(apiResponse));
    }
    throw error;
  },
  parseResponse: async (response: Response): Promise<Record<string, any>> => {
    let rawResponse = await response.text();
    if (response.status === 204 || rawResponse === "") {
      return {};
    }
    try {
      return JSON.parse(rawResponse);
    } catch (err) {
      throw rawResponse;
    }
  },
  checkIfOboxError: response => {
    const {
      status = "",
      msg = "",
      code,
      actionstatusdesc = msg,
      message = actionstatusdesc,
      reason = message,
      error = reason,
      response: errorBody = {
        status,
        message: error
      }
    } = response;

    if (
      (((typeof errorBody.status === "string" &&
        OboxErrorMessages.includes(errorBody.status.toLowerCase())) ||
        OboxErrorMessages.includes(errorBody.message) ||
        status > 400 ||
        response.error) &&
        errorBody?.status?.toLowerCase() !== "success") ||
      !!code
    ) {
      let apiFailure = new Error(
        oboxToWebproMap[errorBody.message] ?? errorBody.message
      );
      // @ts-ignore
      apiFailure.response = new Response(JSON.stringify(response));
      throw apiFailure;
    }
  }
};

export const OBVisitor = {
  async ok<T>(response: Response) {
    let data: T | Record<string, any>;
    try {
      data = await helpers.parseResponse(response);
    } catch (rawResponse) {
      return rawResponse as T;
    }
    helpers.checkIfOboxError(data);
    return data as T;
  },
  fail: helpers.checkForError
};

/**
 * `SimpleApi` is used when the API call can be made without any session attached to it.
 */
export const SimpleApi = createApiClient(
  {
    credentials: new (class SimpleManager extends DefaultCredentialsManager {
      constructor() {
        super(null);
      }
      async isValid() {
        return true;
      }
    })(),
    visitor: OBVisitor
  },
  false
);
