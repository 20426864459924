const COOKIE_SEPARATOR = "; ";
const COOKIE_SPLITTER = "=";

export const parseCookies = (cookies = ""): Record<string, string> =>
  Object.fromEntries(
    cookies.split(COOKIE_SEPARATOR).map(cookie => cookie.split(COOKIE_SPLITTER))
  );

export function parseSearch(searchString) {
  return Object.fromEntries(new URLSearchParams(searchString).entries());
}
