// @ts-check

/** @typedef {"netbanking" | "upi" | "debit" | "credit" | "wallets" | "cheque"} PaymentGatewayTypes */

/**
 * @typedef {Object} SupportConfiguration
 * @property {string} supportEmail
 * @property {string} billingEmail
 * @property {string[]} contactNumbers
 * @property {string} duration
 * @property {string} brandName
 * @property {string?} contactUs
 */

/**
 * @typedef {Object} StoreFrontUrls
 * @property {string} offlinePaymentOptions
 * @property {string} supersiteUrl
 * @property {string} panelInterface
 * @property {string} adamUrl
 * @property {string} whoisUrl
 * @property {string} sampleEmail
 */

/**
 * @typedef {"privacyPolicy" | "legal"} ComplianceUrls
 */

/**
 * @typedef {Object} BrandUrls
 * @property {StoreFrontUrls} storefront
 * @property {Record<ComplianceUrls, string> & {ccpa?: string} } compliance
 */

/**
 * @typedef {Object} PaymentSettings
 * @property {"modern" | "list"} displayMode
 * @property {PaymentGatewayTypes[]} autoRenewDisallowedFor
 * @property {Record<PaymentGatewayTypes, number>} [categories] PG Mapping
 */

/**
 * @typedef {Object} Scenes
 * @property {boolean} [ARTokenNotification]
 * @property {boolean} [CrossSellBanners]
 * @property {boolean} [ViewAffiliate]
 * @property {boolean} AddFunds
 * @property {boolean} Walkthrough
 * @property {boolean} ShowTitanAgreement
 * @property {boolean} ShowOrderTransactions
 * @property {boolean} DisableRenewForOnHoldOrders
 * @property {boolean} AllowSystemInvoiceActions
 * @property {boolean} ShowCustomerIconOnOrderCard
 * @property {boolean} ApplyBuyMoreCustomerStyles
 * @property {boolean} CancelPendingInvoices
 * @property {boolean} GetCustomerShortcuts
 * @property {boolean} ResellerLockOrderForCustomer
 * @property {boolean} UseOBLegalAgreements
 * @property {boolean} ShowCustomerOnHoldOrders
 * @property {boolean} ShowOrderSuspensionAlerts
 * @property {boolean} ShowInvoiceTabs
 * @property {boolean} AllowPaymentForUser
 * @property {boolean} ShowDomainTransfer
 * @property {boolean} EmbedHelpdesk
 */

/** @typedef {"resellerclub" | "hostgator" | "bigrock"} Brand */

/**
 * @typedef {Object} BrandConfiguration
 * @property {Brand} brand
 * @property {Role} preferredUserRole
 * @property {Omit<import("../../webpro").SiteDetails, "url">} siteDetails
 * @property {SupportConfiguration?} support Support configuration for this brand.
 * @property {BrandUrls} urls Brand Specific Urls
 * @property {Scenes} scenes
 * @property {PaymentSettings} paymentSettings PG Mapping
 */

/** @type {Record<string, BrandConfiguration>} */
const BrandConfigurationById = {
  247133: {
    brand: "bigrock",
    preferredUserRole: "customer",
    urls: {
      compliance: {
        legal: "https://www.bigrock.in/support/legal.php",
        privacyPolicy: "https://newfold.com/privacy-center"
      },
      storefront: {
        offlinePaymentOptions: "https://bigrock.in/offline-payment-options",
        supersiteUrl: "https://www.bigrock.in",
        adamUrl: "https://helpdesk.bigrock.in",
        panelInterface: "https://myorders.bigrock.in",
        whoisUrl: "https://bigrock.in/whois-lookup.php",
        sampleEmail: "yourdomain@bigrock.in"
      }
    },
    paymentSettings: {
      displayMode: "modern",
      autoRenewDisallowedFor: ["netbanking"],
      categories: {
        netbanking: 111891,
        upi: 59483,
        debit: 118298,
        credit: 99278,
        wallets: 118299,
        cheque: -1
      }
    },
    scenes: {
      ARTokenNotification: true,
      CrossSellBanners: true,
      ViewAffiliate: true,
      AddFunds: true,
      ShowTitanAgreement: false,
      DisableRenewForOnHoldOrders: false,
      AllowSystemInvoiceActions: false,
      ShowCustomerIconOnOrderCard: false,

      ApplyBuyMoreCustomerStyles: false,
      CancelPendingInvoices: false,
      GetCustomerShortcuts: false,
      UseOBLegalAgreements: false,
      ResellerLockOrderForCustomer: false,

      ShowCustomerOnHoldOrders: false,
      ShowOrderSuspensionAlerts: false,
      ShowInvoiceTabs: false,
      AllowPaymentForUser: false,
      ShowDomainTransfer: true,
      Walkthrough: true,
      ShowOrderTransactions: true,

      EmbedHelpdesk: true
    },
    siteDetails: {
      name: "BigRock.in",
      resellerid: "247133",
      brandingurl: "manage.bigrock.in",
      company: "Endurance International Group (India) Private Limited",
      brandname: "BigRock",
      sellingcurrencysymbol: "INR",
      accountingcurrencysymbol: "INR",
      abuseemailaddr: "abuse@bigrock.com",
      salesemailaddr: "sales@bigrock.com",
      billingemailaddr: "billing@bigrock.com",
      billingtelnocc: "91",
      billingtelno: "8242868080",
      salestelnocc: "91",
      salestelno: "8242868080",
      bu_details: { bu_name: "BigRock", bu_id: "247133" },
      supportsautorenew: true
    },
    support: {
      brandName: "BigRock",
      supportEmail: "support@bigrock.com",
      billingEmail: "billing@bigrock.com",
      contactNumbers: ["0824-6614011", "0824-2868080"],
      duration: "(9AM - 8PM)",
      contactUs: "https://www.bigrock.in/support/contact-us"
    }
  },
  147142: {
    brand: "hostgator",
    preferredUserRole: "customer",
    urls: {
      compliance: {
        legal: "https://www.hostgator.in/support/legal.php",
        privacyPolicy: "https://newfold.com/privacy-center"
      },
      storefront: {
        offlinePaymentOptions:
          "https://www.hostgator.in/support/payment-options.php",
        adamUrl: "https://helpdesk.hostgator.in",
        panelInterface: "https://my.hostgator.in",
        supersiteUrl: "https://www.hostgator.in",
        whoisUrl:
          "https://hostgator.in/domain-registration/domain-whois-lookup.php",
        sampleEmail: "yourdomain@hostgator.in"
      }
    },
    paymentSettings: {
      displayMode: "list",
      autoRenewDisallowedFor: [
        "credit",
        "debit",
        "netbanking",
        "upi",
        "wallets"
      ],
      categories: {
        netbanking: 70521,
        upi: 118275,
        debit: 118283,
        credit: 109381,
        wallets: 118275,
        cheque: -1
      }
    },
    scenes: {
      ARTokenNotification: false,
      CrossSellBanners: true,
      ViewAffiliate: true,
      AddFunds: true,
      ShowTitanAgreement: false,
      DisableRenewForOnHoldOrders: false,
      AllowSystemInvoiceActions: false,
      ShowCustomerIconOnOrderCard: false,

      ApplyBuyMoreCustomerStyles: false,
      CancelPendingInvoices: false,
      GetCustomerShortcuts: false,
      UseOBLegalAgreements: false,
      ResellerLockOrderForCustomer: false,

      ShowCustomerOnHoldOrders: false,
      ShowOrderSuspensionAlerts: false,
      ShowInvoiceTabs: false,
      AllowPaymentForUser: false,
      ShowDomainTransfer: true,
      Walkthrough: true,
      ShowOrderTransactions: true,

      EmbedHelpdesk: true
    },
    siteDetails: {
      name: "Domain Registrar India",
      resellerid: "147142",
      brandingurl: "manage.hostgator.in",
      company: "Endurance International Group (India) Private Limited",
      brandname: "Hostgator India",
      sellingcurrencysymbol: "INR",
      accountingcurrencysymbol: "INR",
      abuseemailaddr: "abuse@hostgator.in",
      salesemailaddr: "sales@hostgator.in",
      billingemailaddr: "billing@hostgator.in",
      billingtelnocc: "91",
      billingtelno: "18002098833",
      salestelnocc: "91",
      salestelno: "18002098833",
      bu_details: { bu_name: "HostGator-India", bu_id: "147142" },
      supportsautorenew: true
    },
    support: {
      brandName: "HostGator-India",
      supportEmail: "support@hostgator.in",
      billingEmail: "billing@hostgator.in",
      contactNumbers: ["0824-6614102", "0824-2863636"],
      duration: "(24/7)",
      contactUs: ""
    }
  },
  20794: {
    brand: "bigrock",
    preferredUserRole: "customer",
    urls: {
      compliance: {
        legal: "https://www.bigrock.in/support/legal.php",
        privacyPolicy: "https://newfold.com/privacy-center"
      },
      storefront: {
        offlinePaymentOptions: "https://www.bigrock.in/offline-payment-options",
        adamUrl: "",
        panelInterface: null,
        supersiteUrl: null,
        whoisUrl: "https://bigrock.in/whois-lookup.php",
        sampleEmail: "yourdomain@bigrock.in"
      }
    },
    paymentSettings: {
      displayMode: "list",
      autoRenewDisallowedFor: [],
      categories: {
        netbanking: 650,
        upi: 300421,
        debit: 650,
        credit: 844,
        wallets: 650,
        cheque: -1
      }
    },
    scenes: {
      ARTokenNotification: false,
      CrossSellBanners: false,
      AddFunds: true,
      ShowTitanAgreement: false,
      DisableRenewForOnHoldOrders: false,
      AllowSystemInvoiceActions: false,
      ShowCustomerIconOnOrderCard: false,

      ApplyBuyMoreCustomerStyles: false,
      CancelPendingInvoices: false,
      GetCustomerShortcuts: false,
      UseOBLegalAgreements: false,
      ResellerLockOrderForCustomer: false,

      ShowCustomerOnHoldOrders: false,
      ShowOrderSuspensionAlerts: false,
      ShowInvoiceTabs: false,
      AllowPaymentForUser: false,
      ShowDomainTransfer: true,

      Walkthrough: true,
      ShowOrderTransactions: true,

      EmbedHelpdesk: false
    },
    siteDetails: null,
    support: {
      brandName: "BigRock",
      supportEmail: "support@bigrock.com",
      billingEmail: "billing@bigrock.com",
      contactNumbers: ["0824-6614011", "0824-2868080"],
      duration: "(24/7)",
      contactUs: "https://www.bigrock.in/support/contact-us"
    }
  },
  20602: {
    brand: "hostgator",
    preferredUserRole: "customer",
    urls: {
      compliance: {
        legal: "https://www.bigrock.in/support/legal.php",
        privacyPolicy: "https://newfold.com/privacy-center"
      },
      storefront: {
        offlinePaymentOptions: "https://www.bigrock.in/offline-payment-options",
        adamUrl: "",
        panelInterface: null,
        supersiteUrl: null,
        whoisUrl: "https://bigrock.in/whois-lookup.php",
        sampleEmail: "yourdomain@bigrock.in"
      }
    },
    paymentSettings: {
      displayMode: "modern",
      autoRenewDisallowedFor: ["netbanking"],
      categories: {
        netbanking: 650,
        upi: 301902,
        debit: 650,
        credit: 844,
        wallets: 650,
        cheque: -1
      }
    },
    scenes: {
      ARTokenNotification: true,
      CrossSellBanners: true,
      ViewAffiliate: true,
      AddFunds: true,
      ShowTitanAgreement: false,
      DisableRenewForOnHoldOrders: false,
      AllowSystemInvoiceActions: false,
      ShowCustomerIconOnOrderCard: false,

      ApplyBuyMoreCustomerStyles: false,
      CancelPendingInvoices: false,
      GetCustomerShortcuts: false,
      UseOBLegalAgreements: false,
      ResellerLockOrderForCustomer: false,

      ShowCustomerOnHoldOrders: false,
      ShowOrderSuspensionAlerts: false,
      ShowInvoiceTabs: false,
      AllowPaymentForUser: false,
      ShowDomainTransfer: true,

      Walkthrough: false,
      ShowOrderTransactions: true,

      EmbedHelpdesk: false
    },
    siteDetails: null,
    support: {
      brandName: "BigRock",
      supportEmail: "support@bigrock.com",
      billingEmail: "billing@bigrock.com",
      contactNumbers: ["0824-6614011", "0824-2868080"],
      duration: "(9AM - 8PM)",
      contactUs: ""
    }
  },
  330623: {
    brand: "resellerclub",
    preferredUserRole: "reseller",
    urls: {
      compliance: {
        legal: "https://india.resellerclub.com/legal-agreements",
        privacyPolicy: "https://newfold.com/privacy-center"
      },
      storefront: {
        offlinePaymentOptions: "",
        adamUrl: "https://helpdesk.resellerclub.com",
        panelInterface: "https://rclubindia.webpropanel.com",
        supersiteUrl: null,
        whoisUrl: "https://rclubindia.myorderbox.com/whois.jsp",
        sampleEmail: "yourdomain@resellerclub.in"
      }
    },
    paymentSettings: {
      displayMode: "list",
      autoRenewDisallowedFor: [],
      categories: null
    },
    scenes: {
      ARTokenNotification: false,
      CrossSellBanners: true,
      ViewAffiliate: true,
      AddFunds: true,
      ShowTitanAgreement: false,
      DisableRenewForOnHoldOrders: false,
      AllowSystemInvoiceActions: false,
      ShowCustomerIconOnOrderCard: false,

      ApplyBuyMoreCustomerStyles: false,
      CancelPendingInvoices: false,
      GetCustomerShortcuts: false,
      UseOBLegalAgreements: false,
      ResellerLockOrderForCustomer: false,

      ShowCustomerOnHoldOrders: false,
      ShowOrderSuspensionAlerts: false,
      ShowInvoiceTabs: false,
      AllowPaymentForUser: false,
      ShowDomainTransfer: true,

      Walkthrough: true,
      ShowOrderTransactions: true,

      EmbedHelpdesk: false
    },
    siteDetails: {
      name: "admin",
      resellerid: "330623",
      brandingurl: "manage.india.resellerclub.com",
      company: "Endurance International Group (India) Private Limited",
      brandname: "ResellerClub - India",
      sellingcurrencysymbol: "INR",
      accountingcurrencysymbol: "INR",
      abuseemailaddr: "abuse@publicdomainregistry.com",
      salesemailaddr: "sales@resellerclub.com",
      billingemailaddr: "billing@resellerclub.com",
      billingtelnocc: "91",
      billingtelno: "2267209090",
      salestelnocc: "91",
      salestelno: "2267209090",
      bu_details: { bu_name: "ResellerClub-India", bu_id: "330623" },
      supportsautorenew: true
    },
    support: {
      brandName: "ResellerClub - India",
      supportEmail: "",
      billingEmail: "",
      contactNumbers: [],
      duration: "",
      contactUs: ""
    }
  },
  331653: {
    brand: "resellerclub",
    preferredUserRole: "reseller",
    urls: {
      compliance: {
        legal: "https://resellerclub.com/legal-agreements",
        privacyPolicy: "https://newfold.com/privacy-center"
      },
      storefront: {
        offlinePaymentOptions: "",
        adamUrl: "https://helpdesk.resellerclub.com",
        panelInterface: "https://rclubbr.webpropanel.com",
        supersiteUrl: null,
        whoisUrl: "https://rclubbr.myorderbox.com/whois.jsp",
        sampleEmail: "yourdomain@resellerclub.com"
      }
    },
    paymentSettings: {
      displayMode: "list",
      autoRenewDisallowedFor: [],
      categories: null
    },
    scenes: {
      ARTokenNotification: false,
      CrossSellBanners: true,
      AddFunds: true,
      ShowTitanAgreement: false,
      DisableRenewForOnHoldOrders: false,
      AllowSystemInvoiceActions: false,
      ShowCustomerIconOnOrderCard: false,

      ApplyBuyMoreCustomerStyles: false,
      CancelPendingInvoices: false,
      GetCustomerShortcuts: false,
      UseOBLegalAgreements: false,
      ResellerLockOrderForCustomer: false,

      ShowCustomerOnHoldOrders: false,
      ShowOrderSuspensionAlerts: false,
      ShowInvoiceTabs: false,
      AllowPaymentForUser: false,
      ShowDomainTransfer: true,

      Walkthrough: true,
      ShowOrderTransactions: true,

      EmbedHelpdesk: false
    },
    siteDetails: null,
    support: {
      brandName: "ResellerClub Brazil",
      supportEmail: "",
      billingEmail: "",
      contactNumbers: [],
      duration: "",
      contactUs: ""
    }
  },
  355948: {
    brand: "resellerclub",
    preferredUserRole: "reseller",
    urls: {
      compliance: {
        legal: "https://resellerclub.com/legal-agreements",
        privacyPolicy: "https://newfold.com/privacy-center"
      },
      storefront: {
        offlinePaymentOptions: "",
        adamUrl: "https://helpdesk.resellerclub.com",
        panelInterface: "https://rclubtr.webpropanel.com",
        supersiteUrl: null,
        whoisUrl: "https://rclubtr.myorderbox.com/whois.jsp",
        sampleEmail: "yourdomain@resellerclub.com"
      }
    },
    paymentSettings: {
      displayMode: "list",
      autoRenewDisallowedFor: [],
      categories: null
    },
    scenes: {
      ARTokenNotification: false,
      CrossSellBanners: true,
      AddFunds: true,
      ShowTitanAgreement: false,
      DisableRenewForOnHoldOrders: false,
      AllowSystemInvoiceActions: false,
      ShowCustomerIconOnOrderCard: false,

      ApplyBuyMoreCustomerStyles: false,
      CancelPendingInvoices: false,
      GetCustomerShortcuts: false,
      UseOBLegalAgreements: false,
      ResellerLockOrderForCustomer: false,

      ShowCustomerOnHoldOrders: false,
      ShowOrderSuspensionAlerts: false,
      ShowInvoiceTabs: false,
      AllowPaymentForUser: false,
      ShowDomainTransfer: true,

      Walkthrough: true,
      ShowOrderTransactions: true,

      EmbedHelpdesk: false
    },
    siteDetails: null,
    support: {
      brandName: "ResellerClub Turkey",
      supportEmail: "",
      billingEmail: "",
      contactNumbers: [],
      duration: "",
      contactUs: ""
    }
  },
  415316: {
    brand: "resellerclub",
    preferredUserRole: "reseller",
    urls: {
      compliance: {
        legal: "https://resellerclub.com/legal-agreements",
        privacyPolicy: "https://newfold.com/privacy-center"
      },
      storefront: {
        offlinePaymentOptions: "",
        adamUrl: "https://helpdesk.resellerclub.com",
        panelInterface: "https://rclubid.webpropanel.com",
        supersiteUrl: null,
        whoisUrl: "https://rclubid.myorderbox.com/whois.jsp",
        sampleEmail: "yourdomain@resellerclub.com"
      }
    },
    paymentSettings: {
      displayMode: "list",
      autoRenewDisallowedFor: [],
      categories: null
    },
    scenes: {
      ARTokenNotification: false,
      CrossSellBanners: true,
      AddFunds: true,
      ShowTitanAgreement: false,
      DisableRenewForOnHoldOrders: false,
      AllowSystemInvoiceActions: false,
      ShowCustomerIconOnOrderCard: false,

      ApplyBuyMoreCustomerStyles: false,
      CancelPendingInvoices: false,
      GetCustomerShortcuts: false,
      UseOBLegalAgreements: false,
      ResellerLockOrderForCustomer: false,

      ShowCustomerOnHoldOrders: false,
      ShowOrderSuspensionAlerts: false,
      ShowInvoiceTabs: false,
      AllowPaymentForUser: false,
      ShowDomainTransfer: true,

      Walkthrough: true,
      ShowOrderTransactions: true,

      EmbedHelpdesk: false
    },
    siteDetails: null,
    support: {
      brandName: "ResellerClub Indonesia",
      supportEmail: "",
      billingEmail: "",
      contactNumbers: [],
      duration: "",
      contactUs: ""
    }
  },
  1: {
    brand: "resellerclub",
    preferredUserRole: "reseller",
    urls: {
      compliance: {
        legal: "https://resellerclub.com/legal-agreements",
        privacyPolicy: "https://newfold.com/privacy-center",
        ccpa: "https://www.newfold.com/privacy-center/addendum-for-california-users"
      },
      storefront: {
        offlinePaymentOptions: "",
        adamUrl: "https://helpdesk.resellerclub.com",
        panelInterface: "https://resellerclub.webpropanel.com",
        supersiteUrl: null,
        whoisUrl: "https://manage.resellerclub.com/whois.jsp",
        sampleEmail: "yourdomain@resellerclub.com"
      }
    },
    paymentSettings: {
      displayMode: "list",
      autoRenewDisallowedFor: [],
      categories: null
    },
    scenes: {
      ARTokenNotification: false,
      CrossSellBanners: true,
      ViewAffiliate: true,
      AddFunds: true,
      ShowTitanAgreement: false,
      DisableRenewForOnHoldOrders: false,
      AllowSystemInvoiceActions: false,
      ShowCustomerIconOnOrderCard: false,

      ApplyBuyMoreCustomerStyles: false,
      CancelPendingInvoices: false,
      GetCustomerShortcuts: false,
      UseOBLegalAgreements: false,
      ResellerLockOrderForCustomer: false,

      ShowCustomerOnHoldOrders: false,
      ShowOrderSuspensionAlerts: false,
      ShowInvoiceTabs: false,
      AllowPaymentForUser: false,
      ShowDomainTransfer: true,

      Walkthrough: true,
      ShowOrderTransactions: true,

      EmbedHelpdesk: false
    },
    siteDetails: {
      name: "ResellerClub",
      resellerid: "1",
      brandingurl: "manage.resellerclub.com",
      company: "P.D.R. Solutions (U.S.) LLC",
      brandname: "ResellerClub",
      sellingcurrencysymbol: "USD",
      accountingcurrencysymbol: "INR",
      abuseemailaddr: "abuse@publicdomainregistry.com",
      salesemailaddr: "sales@resellerclub.com",
      billingemailaddr: "billing@resellerclub.com",
      billingtelnocc: "1",
      billingtelno: "4152361970",
      salestelnocc: "1",
      salestelno: "4152361970",
      bu_details: { bu_name: "ResellerClub-Global", bu_id: "1" },
      supportsautorenew: true
    },
    support: {
      brandName: "ResellerClub",
      supportEmail: "",
      billingEmail: "",
      contactNumbers: [],
      duration: "",
      contactUs: ""
    }
  },
  999999998: {
    brand: "resellerclub",
    preferredUserRole: "reseller",
    urls: {
      compliance: {
        legal: "https://resellerclub.com/legal-agreements",
        privacyPolicy: "https://newfold.com/privacy-center"
      },
      storefront: {
        offlinePaymentOptions: "",
        adamUrl: "",
        panelInterface: "https://demo.webpropanel.com",
        supersiteUrl: null,
        whoisUrl: "https://demo.myorderbox.com/whois.jsp",
        sampleEmail: "yourdomain@resellerclub.com"
      }
    },
    paymentSettings: {
      displayMode: "list",
      autoRenewDisallowedFor: [],
      categories: null
    },
    scenes: {
      ARTokenNotification: false,
      CrossSellBanners: true,
      ViewAffiliate: true,
      AddFunds: true,
      ShowTitanAgreement: false,
      DisableRenewForOnHoldOrders: false,
      AllowSystemInvoiceActions: false,
      ShowCustomerIconOnOrderCard: false,

      ApplyBuyMoreCustomerStyles: false,
      CancelPendingInvoices: false,
      GetCustomerShortcuts: false,
      UseOBLegalAgreements: false,
      ResellerLockOrderForCustomer: false,

      ShowCustomerOnHoldOrders: false,
      ShowOrderSuspensionAlerts: false,
      ShowInvoiceTabs: false,
      AllowPaymentForUser: false,
      ShowDomainTransfer: true,
      Walkthrough: true,
      ShowOrderTransactions: true,

      EmbedHelpdesk: false
    },
    siteDetails: null,
    support: {
      brandName: "Demo Registrar",
      supportEmail: "",
      billingEmail: "",
      contactNumbers: [],
      duration: "",
      contactUs: ""
    }
  }
};

/**
 *
 * @param {Role} role
 * @returns {BrandConfiguration}
 */
function createDefaultBrandConfigurationFor(role) {
  return {
    brand: "resellerclub",
    preferredUserRole: "customer",
    urls: {
      compliance: {
        legal: "/legal",
        privacyPolicy: "/legal"
      },
      storefront: {
        offlinePaymentOptions: "",
        adamUrl: "",
        panelInterface: window.location.origin,
        supersiteUrl: null,
        whoisUrl: "https://publicdomainregistry.com/whois",
        sampleEmail: "yourdomain@domain.com"
      }
    },
    paymentSettings: {
      displayMode: "list",
      autoRenewDisallowedFor: [],
      categories: null
    },
    scenes: {
      ARTokenNotification: false,
      CrossSellBanners: false,
      ViewAffiliate: false,
      AddFunds: role === "reseller",
      ShowTitanAgreement: role === "customer",
      DisableRenewForOnHoldOrders: role === "customer",
      AllowSystemInvoiceActions: role === "customer",
      ShowCustomerIconOnOrderCard: role === "customer",
      ApplyBuyMoreCustomerStyles: role === "customer",
      CancelPendingInvoices: role === "customer",
      GetCustomerShortcuts: role === "customer",
      UseOBLegalAgreements: role === "customer",
      ResellerLockOrderForCustomer: role === "customer",
      ShowCustomerOnHoldOrders: role === "customer",
      ShowOrderSuspensionAlerts: role === "customer",
      ShowInvoiceTabs: role !== "customer",
      AllowPaymentForUser: role !== "customer",
      ShowDomainTransfer: role !== "customer",
      Walkthrough: role === "reseller",
      ShowOrderTransactions: role === "reseller",
      EmbedHelpdesk: false
    },
    siteDetails: null,
    support: {
      brandName: "",
      supportEmail: "",
      billingEmail: "",
      contactNumbers: [],
      duration: "",
      contactUs: ""
    }
  };
}

export const BrandConfigurationOperations = {
  /**
   *
   * @param {BrandConfiguration} brandConfiguration
   * @param {PaymentGatewayTypes} category
   * @returns {number}
   */
  mapCategoryToId: (brandConfiguration, category) =>
    brandConfiguration.paymentSettings.categories[category],
  /**
   *
   * @param {string} parentId
   * @param {Role?} role
   * @returns {BrandConfiguration?}
   */
  findById: (parentId, role = null) => {
    const configuration = BrandConfigurationById[parentId];
    if (configuration) {
      return configuration;
    }
    return role ? createDefaultBrandConfigurationFor(role) : null;
  },
  /**
   *
   * @param {import("react-redux").DefaultRootState} state
   * @returns {BrandConfiguration}
   */
  fromState: state =>
    BrandConfigurationOperations.findById(
      state.root.userInfo.parentid,
      state.root.userInfo.role
    ),
  /**
   * @param {string} url
   * @returns {BrandConfiguration?}
   */
  findByUrl: url =>
    Object.values(BrandConfigurationById).find(config =>
      config.urls.storefront.panelInterface?.includes(url)
    ),
  /**
   *
   * @param {Brand} brand
   * @returns {string}
   */
  findAnalyticsId: brand =>
    ["hostgator", "bigrock"].includes(brand) ? "GTM-TGPWBCQ8" : "GTM-5BRG5GJ"
};
